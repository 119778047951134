import React, { useEffect, useState } from "react"
import styled from "styled-components"

import SectionHeader from "../pages/settings/components/_SectionHeader"
import { RockPointsDisclaimer } from "../pages/settings/components/_RockPoints"
// import Button from "../pages/settings/components/_Button"

// import stripeIcon from "../imgs/stripe-icon-blue.svg"
// import poweredByStripeCards from "../imgs/powered_by_stripe_cards.png"
import rockPointsLogo from "../imgs/rockpoints-logo.svg"
import coinbaseLogo from "../imgs/coinbase.png"
import coinbaseSquareLogo from "../imgs/coinbase-square.png"
import coinifyLogo from "../imgs/coinify-logo.png"
import coinifySquareLogo from "../imgs/coinify-square.png"

import { get } from "lodash"
import authStore from "../stores/auth"
import CryptoActValidationModal from "./CryptoActValidationModal"
import { METHODS } from "../pages/checkout"

const CRYPTO_METHODS = {
  COINBASE: "COINBASE",
  COINIFY: "COINIFY",
}

const MyPaymentInfoSection = styled.section`
  margin-bottom: 42px;

  .payment-methods-div {
    margin-top: 6px;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .stripe {
    margin-left: 6px;
  }

  .blue-color {
    color: #419bf9;
  }

  .text-bold {
    font-weight: 700;
  }

  .button-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 24px;

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  }

  .button-container.grow button {
    flex-grow: 1;
  }

  .button-container button {
    background: white;
    border: 1px solid #c9c9ca;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: all 0.35s;
    line-height: 1.5;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.04),
      0px 5px 10px rgba(0, 0, 0, 0.04);

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
    }

    @media screen and (max-width: 960px) {
      width: 100%;
    }
    @media screen and (min-width: 961px) {
      max-width: 31%;
    }

    &:disabled {
      transform: scale(0.95);
      background: #f0f0f0;
      cursor: progress;
    }
  }
  .no-margin {
    margin: 0px !important;
  }
  .external-stripe-credit-wrapper {
    margin-left: 12px;
  }
  .button-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    font-size: 12px;

    .expand {
      flex: 1;
    }

    .rockPoints {
      width: 55px;
      margin-right: 12px;
      margin-left: 12px;
    }
    .poweredByStripeCards {
      width: 100px;
      margin-right: 12px;
      margin-left: 12px;
    }

    .coinbase {
      width: 100px;
    }
    .coinify {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  .coinbase-square {
    width: 14px;
    margin-top: 4px;
  }

  .credit-input {
    font-size: 14px;
    line-height: 1.2;
    padding: 14px 24px;
    color: #333;
    border: 1px solid rgba(25, 35, 50, 0.1);
    border-radius: 4px;
    flex: 1;
    margin: 0 14px 24px;
    outline: none;

    &:focus {
      box-shadow: 0 1px 4px 0 rgba(88, 98, 113, 0.08);
      border-color: rgba(25, 35, 50, 0.2);
    }

    @media screen and (max-width: 960px) {
      width: 50%;
    }
  }
`
let captchaRef = null
let _widgetID = undefined
export default function PaymentInfoSection({
  manageSubsLink,
  fetchSubsLink,
  rpStore,
  // fetchRenewals,
}) {
  const { nextBillDiscount, fetchHistory } = rpStore
  const [cryptoCreditAmount, setCryptoCreditAmount] = useState(null)
  const [cryptoMethod, setCryptoMethod] = useState(null)
  const [redeeming, setRedeeming] = useState(false)
  const [greToken, setGreToken] = useState(null)
  const [askCreditAmount, setAskCreditAmount] = useState(false)
  // const [greTokenCredit, setGreTokenCredit] = useState(null)
  const renderRecaptchaV2 = (isBuyCredit = false, amount = 0) => {
    if (window.grecaptcha && _widgetID === undefined) {
      const wrapper = document.createElement("div")

      _widgetID = window.grecaptcha.render(wrapper, {
        sitekey: process.env.GRECAPTCHA_V2_KEY,
        callback: greToken =>
          handleRecaptchaV2Response(greToken, isBuyCredit, amount),
        theme: "light",
      })

      captchaRef.appendChild(wrapper)
    } else {
      // console.log("renderRecaptchaV2 fail")
    }
  }
  const fetchSubsWithRecaptcha = async () => {
    let _greToken, v2
    if (greToken) {
      // console.log("initial greToken present", greToken)
      v2 = true
      _greToken = greToken
      fetchSubsLink(_greToken, v2)
    } else {
      // console.log("initial greToken missing", greToken)
      v2 = false
      try {
        _greToken = await window.grecaptcha.execute(
          process.env.GRECAPTCHA_KEY,
          {
            action: "submit",
          }
        )
        // console.log("v2 greToken", _greToken)
        fetchSubsLink(_greToken, v2)
      } catch (e) {
        console.error(e)
        console.error("Fallback to recaptcha v2")
        return renderRecaptchaV2()
      }
    }
  }
  const handleRef = ref => {
    if (!ref) {
      return
    }
    captchaRef = ref
  }
  const handleRecaptchaV2Response = async (greToken, isBuyCredit) => {
    // console.log("handling v2 response", { greToken, isBuyCredit})
    if (isBuyCredit) {
      // try {
      //   setRedeeming(true)
      //   await rpStore.createInvoice(amount, greToken, true)
      //   setGreTokenCredit(greToken)
      //   setRedeeming(false)
      // } catch (e) {
      //   console.error(e)
      //   setRedeeming(false)
      // }
    } else {
      fetchSubsLink(greToken, true)
      setGreToken(greToken)
    }
  }
  useEffect(() => {
    return () => {
      // console.log("unmounting", !!window.grecaptcha)
      if (window.grecaptcha && _widgetID) window.grecaptcha.reset(_widgetID)
    }
  })
  useEffect(() => {
    fetchSubsWithRecaptcha()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSubsLink])
  const mpEmail = get(authStore, "state.user.email", "email not found")
  const [cryptoActivationModal, setCryptoActivationModal] = useState(false)

  // const handleClick = value => async () => {
  //   setRedeeming(true)
  //   await rpStore.redeemPoints(value * rpStore.rockPointsRate)
  //   await fetchRenewals(true)
  //   setRedeeming(false)
  // }
  const handleCreditClick = async (paymentMethod, value) => {
    setRedeeming(true)
    setCryptoMethod(paymentMethod)
    setCryptoCreditAmount(value)
    setAskCreditAmount(
      paymentMethod === CRYPTO_METHODS.COINIFY && value === null ? true : false
    )
    setCryptoActivationModal(true)
  }
  const onCryptoActModalHide = () => {
    setRedeeming(false)
    setCryptoMethod(null)
    setCryptoCreditAmount(null)
    setCryptoActivationModal(false)
  }
  const onCryptoActValidateSuccess = async (value = null) => {
    setCryptoActivationModal(false)
    setRedeeming(true)
    if (cryptoMethod === METHODS.COINIFY) {
      const response = await rpStore.addCreditCoinify(
        value ? value : cryptoCreditAmount,
        mpEmail
      )
      if (response && response.paymentWindowUrl) {
        localStorage.setItem("paymentType", "addCredit")
        window.location.href = response.paymentWindowUrl
      } else {
        setRedeeming(false)
        setCryptoCreditAmount(null)
        setCryptoMethod(null)
      }
      return
    }
    const response = await rpStore.createCharge(cryptoCreditAmount, mpEmail)
    if (response && response.hosted_url) {
      window.location.href = response.hosted_url
    } else {
      setRedeeming(false)
      setCryptoCreditAmount(null)
      setCryptoMethod(null)
    }
  }
  // const addStripeCredit = async () => {
  //   const amount = parseFloat(document.getElementById("credit").value)
  //   if (isNaN(amount) || amount < 0) return
  //   let _greToken, v2
  //   if (greTokenCredit) {
  //     v2 = true
  //     _greToken = greTokenCredit
  //     setRedeeming(true)
  //     await rpStore.createInvoice(amount, _greToken, v2)
  //     setRedeeming(false)
  //   } else {
  //     v2 = false
  //     try {
  //       _greToken = await window.grecaptcha.execute(
  //         process.env.GRECAPTCHA_KEY,
  //         {
  //           action: "submit",
  //         }
  //       )
  //       setRedeeming(true)
  //       await rpStore.createInvoice(amount, _greToken, v2)
  //       setRedeeming(false)
  //     } catch (e) {
  //       console.error(e)
  //       console.error("Fallback to recaptcha v2")
  //       setRedeeming(false)
  //       return renderRecaptchaV2(true, amount)
  //     }
  //   }
  // }
  // const isMPManaged = rpStore.preCreditDigit === 1
  useEffect(() => {
    fetchHistory()
    rpStore.fetchData()
  }, [fetchHistory, rpStore])

  return (
    <MyPaymentInfoSection>
      <div className="recaptcha-container" ref={handleRef} />
      <SectionHeader title="Credit">
        {nextBillDiscount > 0 && (
          <span className="green">${nextBillDiscount.toFixed(2)}</span>
        )}
      </SectionHeader>

      <p>
        You have ${nextBillDiscount.toFixed(2)} of account credit.{" "}
        {nextBillDiscount > 0 && (
          <span>
            This amount will be applied to your next subscription payment(s).
          </span>
        )}
      </p>
      <br />

      <SectionHeader title="Add Credit" />

      <div className="button-header">
        <img src={rockPointsLogo} className="rockPoints" alt="rockPoints" />
        <span className="expand">{RockPointsDisclaimer}</span>
        {/* <span className="gray">
          You have <span className="purple">{rpStore.rockPoints}</span>{" "}
          RockPoints!
        </span> */}
      </div>

      {/* <div className="button-container grow">
        {[10, 25, 50].map(value => {
          return (
            <button
              key={value}
              onClick={handleClick(value)}
              disabled={redeeming}
            >
              <span className="green">${value}.00</span>{" "}
              <span className="gray">USD Credit</span>
              <br />
              <span className="small purple">
                {value * rpStore.rockPointsRate} RockPoints
              </span>
            </button>
          )
        })}
      </div> */}

      <div className="button-header">
        <img src={coinbaseLogo} className="coinbase" alt="coinbase" />
        <span className="expand">Buy credit with cryptocurrency</span>
      </div>

      <div className="button-container">
        {[50, 100, 250].map(value => {
          return (
            <button
              key={value}
              disabled={redeeming}
              onClick={() => handleCreditClick(CRYPTO_METHODS.COINBASE, value)}
            >
              <span className="green">${value}.00</span>{" "}
              <span className="gray">USD Credit</span>
              <br />
              <img
                src={coinbaseSquareLogo}
                alt="coinbase"
                className="coinbase-square"
              />
            </button>
          )
        })}
        <button
          disabled={redeeming}
          onClick={() => handleCreditClick(CRYPTO_METHODS.COINBASE)}
        >
          <span className="green">Any</span>{" "}
          <span className="gray">USD Credit</span>
          <br />
          <img
            src={coinbaseSquareLogo}
            alt="coinbase"
            className="coinbase-square"
          />
        </button>
      </div>

      <div className="button-header">
        <img src={coinifyLogo} className="coinbase coinify" alt="coinify" />
        <span className="expand">Buy credit with cryptocurrency</span>
      </div>

      <div className="button-container">
        {[50, 100, 250].map(value => {
          return (
            <button
              key={value}
              disabled={redeeming}
              onClick={() => handleCreditClick(CRYPTO_METHODS.COINIFY, value)}
            >
              <span className="green">${value}.00</span>{" "}
              <span className="gray">USD Credit</span>
              <br />
              <img
                src={coinifySquareLogo}
                alt="coinify"
                className="coinbase-square"
              />
            </button>
          )
        })}
        <button
          disabled={redeeming}
          onClick={() => handleCreditClick(CRYPTO_METHODS.COINIFY, null)}
        >
          <span className="green">Any</span>{" "}
          <span className="gray">USD Credit</span>
          <br />
          <img
            src={coinifySquareLogo}
            alt="coinify"
            className="coinbase-square"
          />
        </button>
      </div>

      {/* <div className="button-header no-margin">
        <img
          src={poweredByStripeCards}
          className="poweredByStripeCards"
          alt="stripe"
        />
        <span className="expand">
          Add credit using a credit or a debit card
        </span>
      </div> */}

      {/* Below:=> Stripe credit under maintainence template */}
      {/* <div class="button-container external-stripe-credit-wrapper">
        <p>
          <p class="blue-color text-bold">
            Credit and Debit card payments are currently under maintenance, and
            will be back soon. Thank you for your patience.
          </p>
        </p>
      </div> */}

      {/* Below:=> Add stripe credit with input box template */}
      {/* <div>
        <input className="credit-input" id="credit" placeholder="$ Credit" />
        <Button onClick={addStripeCredit} disabled={redeeming}>
          Add Credit
        </Button>
      </div> */}

      <SectionHeader title="Subscription" />

      <div className="payment-methods-div">
        <p>
          <a href={manageSubsLink}>Click here</a> to add/manage payment methods
        </p>
      </div>
      <CryptoActValidationModal
        paymentMethod={cryptoMethod}
        isOpen={cryptoActivationModal}
        mpEmail={mpEmail}
        onHide={onCryptoActModalHide}
        onSubmit={onCryptoActValidateSuccess}
        askCreditAmount={askCreditAmount}
      />
    </MyPaymentInfoSection>
  )
}
